<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small" @submit.native.prevent>
				<el-form-item label="名称" label-width="50px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" @keyup.enter.native="query"/>
				</el-form-item>
<!-- 				<el-form-item label="所属分会" label-width="80px" prop="grade">
				  <el-select v-model="queryForm.grade" placeholder="请选择所属分会" size="small" class="w-100">
				     <el-option label="全部" value=""></el-option>
				     <el-option v-for="(item,index) in grades" :key="index"
						 :label="item.name"
						 :value="item.id">
				       </el-option>
				     </el-select>
				  </el-select>
				</el-form-item> -->
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<el-button type="danger" icon="el-icon-delete" size="mini" @click="handleAllDel" v-if="ids.length > 0">批量删除</el-button>
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				style="width: 100%"
				@selection-change="handleSelectionChange"
			 >
			   <el-table-column
					type="selection"
					width="55">
			    </el-table-column>
<!-- 			    <el-table-column type="expand">
			       <template slot-scope="props">
			         <el-form label-position="left" inline class="demo-table-expand">
			           <el-form-item label="名称" v-if="props.row.data.name">
			             <span>{{ props.row.data.name }}</span>
			           </el-form-item>
					   <el-form-item label="地址" v-if="props.row.data.address">
					     <span>{{ props.row.data.address }}</span>
					   </el-form-item>
					   <el-form-item label="性质" v-if="props.row.data.dw_xz">
					     <span>{{ props.row.data.dw_xz }}</span>
					   </el-form-item>
					   <el-form-item label="注册资本" v-if="props.row.data.reg_money">
					     <span>{{ props.row.data.reg_money + '万'}}</span>
					   </el-form-item>
			           <el-form-item label="法人" v-if="props.row.data.legal_person">
			             <span>{{ props.row.data.legal_person }}</span>
			           </el-form-item>
					   <el-form-item label="职位" v-if="props.row.data.l_duties">
					     <span>{{ props.row.data.l_duties }}</span>
					   </el-form-item>
			           <el-form-item label="电话" v-if="props.row.data.l_phone">
			             <span>{{ props.row.data.l_phone }}</span>
			           </el-form-item>
			           <el-form-item label="手机" v-if="props.row.data.l_mobile">
			             <span>{{ props.row.data.l_mobile }}</span>
			           </el-form-item>
			           <el-form-item label="传真" v-if="props.row.data.l_fax">
			             <span>{{ props.row.data.l_fax }}</span>
			           </el-form-item>
			           <el-form-item label="邮箱" v-if="props.row.data.l_email">
			             <span>{{ props.row.data.l_email }}</span>
			           </el-form-item>
			           <el-form-item label="联系人" v-if="props.row.data.work_person">
			             <span>{{ props.row.data.work_person }}</span>
			           </el-form-item>
					   <el-form-item label="职位" v-if="props.row.data.work_duties">
					     <span>{{ props.row.data.work_duties }}</span>
					   </el-form-item>
					   <el-form-item label="电话" v-if="props.row.data.work_phone">
					     <span>{{ props.row.data.work_phone }}</span>
					   </el-form-item>
					   <el-form-item label="手机" v-if="props.row.data.work_mobile">
					     <span>{{ props.row.data.work_mobile }}</span>
					   </el-form-item>
					   <el-form-item label="传真" v-if="props.row.data.work_fax">
					     <span>{{ props.row.data.work_fax }}</span>
					   </el-form-item>
					   <el-form-item label="邮箱" v-if="props.row.data.work_email">
					     <span>{{ props.row.data.work_email }}</span>
					   </el-form-item>
					   <el-form-item label="人数" v-if="props.row.data.people_num">
					     <span>{{ props.row.data.people_num }}</span>
					   </el-form-item>
					   <el-form-item label="邮编" v-if="props.row.data.zip_code">
					     <span>{{ props.row.data.zip_code }}</span>
					   </el-form-item>
					   <el-form-item label="承装等级" v-if="props.row.data.cz_grade">
					     <span>{{ props.row.data.cz_grade }}</span>
					   </el-form-item>
					   <el-form-item label="承修等级" v-if="props.row.data.cx_grade">
					     <span>{{ props.row.data.cx_grade }}</span>
					   </el-form-item>
					   <el-form-item label="承试等级" v-if="props.row.data.cs_grade">
					     <span>{{ props.row.data.cs_grade }}</span>
					   </el-form-item>
					   <el-form-item label="许可证编号" v-if="props.row.data.cz_code">
					     <span>{{ props.row.data.cz_code }}</span>
					   </el-form-item>
					   <el-form-item label="许可证有效期" v-if="props.row.data.xkz_time">
					     <span>{{ props.row.data.xkz_time }}</span>
					   </el-form-item>
					   <el-form-item label="其他电力相关资质" v-if="props.row.data.other_aptitude">
					     <span>{{ props.row.data.other_aptitude }}</span>
					   </el-form-item>
					   <el-form-item label="简介" v-if="props.row.data.desc">
					     <span>{{ props.row.data.desc }}</span>
					   </el-form-item>
			         </el-form>
			       </template>
			    </el-table-column> -->
				<el-table-column label="ID" width="80" align="center">
				  <template slot-scope="scope">
					{{ scope.row.id }}
				  </template>
				</el-table-column>
				<el-table-column label="名称" min-width="120" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.name }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="区域" min-width="80" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.area }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="分会类型" min-width="100" align="center">
				  <template slot-scope="scope">
					{{ scope.row.grade_name }}
				  </template>
				</el-table-column>
				<el-table-column label="类型" min-width="100" align="center">
				  <template slot-scope="scope">
					{{ scope.row.msg }}
				  </template>
				</el-table-column>
				<el-table-column label="变更内容" min-width="110" align="center" :show-overflow-tooltip="true">
				  <template slot-scope="scope">
					{{ scope.row.data_desc }}
				  </template>
				</el-table-column>
				<el-table-column label="变更人" min-width="100" align="center">
				  <template slot-scope="scope">
					{{ scope.row.bgr }}
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="160">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.ctime }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="操作" min-width="80" align="center">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="查看" placement="top">
						<el-button type="success" icon="el-icon-view" size="mini" circle
							@click.stop="handleView(scope.row)"></el-button>
					</el-tooltip>
					<el-tooltip class="item" effect="dark" content="详情" placement="top">
						<el-button type="primary" icon="el-icon-tickets" size="mini" circle
							@click.stop="handleInfo(scope.row)"></el-button>
					</el-tooltip>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		</el-card>
		<!-- 编辑-->
		<el-dialog :visible.sync="open" width="600px" :close-on-click-modal="false" append-to-body :title="title" center>
          <div class="w-100 flex flex-column">
          	 <div class="w-100 flex align-center border" style="height: 35px;" v-for="(item,index) in bg_data" :key="index">
				 <div class="flex-1 flex-all">{{item.name || ''}}：</div>
				 <div class="flex-1 flex-all text-info">变更为</div>
				 <div class="flex-2 flex-all">{{item.value || ''}}</div>
			 </div>
          </div>
		  <div slot="footer" class="dialog-footer">
		    <el-button size="mini" @click="cancel">取 消</el-button>
		    <!-- <el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button> -->
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'user_log',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
					grade:'',
				},
				form:{
					username:'',
					password:'',
					mobile:'',
					role_ids:[],
					status:1,
					avatar:'',
					url:'',
				},
				rules:{
					username: [
					  { required: true, message: '请输入名称', trigger: 'blur' }
					],
					role_ids: [
					  { required: true, message: '请选择角色', trigger: ['blur', 'change'] }
					],
				},
				ids:[],
				grades:[],
				bg_data: [],
			}
		},
		mounted() {
			this.getGrades()
		},
		methods:{
			reset() {
			  this.form = {
			   username:'',
			   password:'',
			   mobile:'',
			   role_ids:[],
			   status:1,
			   avatar:'',
			   url:'',
			  }
			  this.resetForm('form')
			},
			getGrades(){
				this.axios.get('/manage/user_grade/all').then(res=>{
				  if(res.status){
					let  grades = res.data
					let index = grades.findIndex(v=>v.id == 2)
					grades.splice(index,1)
					this.grades = grades
				  }
				})
			},
			handleView(row){
				this.$router.push({
				  path:"/user_detail",
				  query: {
					  id:row.user_id
				  }
				})
			},
			handleInfo(row){
				this.title = '变更详情'
				this.open = true
				this.bg_data = row.data
			},
		}
	}
</script>

<style>
 .demo-table-expand {
    font-size: 0;
  }
  .demo-table-expand label {
    width: 130px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>